// var loaded = false;

// Ждём загрузки всех картинок и скрываем preloader
$(window).on('load', function(){
    // loaded = true;
    $('.preloader').fadeOut();
});

$(document).ready(function(){
	var $btnMainMenu 	= $(".btn-main-menu"),
		$header 		= $('header'),
		$mainMenuLg 	= $('.main-menu-lg'),
		isMenuActive	= false,
		$langWrp 		= $('.lang-wrp'),
		$logoHeader 	= $('.logo-wrp.main');

	//Открытие прелодера
    // if (!loaded){
    //     $('.preloader').show();
    // }

	// Постраничная прокрутка
	$('main').fullpage({
		navigation: true,
		navigationPosition: 'right',
		verticalCentered: false,
		onLeave: function(index, nextIndex, direction){

			// Если меню открыто, то не меняем секцию
			if (isMenuActive){
				return false;
			}

			// На первой секции отображаем горизонтальное меню, на остальных бургер меню
			if ($(window).width() > 1279){
				if(index == 1 && direction =='down'){
					$btnMainMenu.fadeIn();
					$mainMenuLg.addClass('hidden');
					$logoHeader.addClass('active');
				} else if(nextIndex == 1 && direction == 'up'){
					$btnMainMenu.fadeOut();
					$mainMenuLg.removeClass('hidden');
					$logoHeader.removeClass('active');
				}
			}
		}
	});

	// Открытие выпадающего меню
	$btnMainMenu.on('click', function(){
		
		// Значит закрываем окно с видео
		if ($(this).data('video')){
    		return;
    	}

		$header.toggleClass('opened');
		if ($(window).width() < 1280){
			$langWrp.toggle();
		}
		isMenuActive ? isMenuActive = false : isMenuActive = true;
	});

	// Открытие языковой панели
	$langWrp.on('click', '.link.active', function(){
		var $parent = $(this).parent();
		if ($parent.hasClass('opened')){
			$parent.removeClass('opened');
		} else {
			$parent.addClass('opened');
			// Ставим интервал
			// каждые 2 секунды проверяем, если кнопки языков не под ховером,
			// то сворачиваем панельку
			var langTimer = setInterval(function(){

				if(!$parent.is(':hover')) {
					$parent.removeClass('opened');
					clearInterval(langTimer);
				}

			}, 2000);
		}
	});
});

// Запуск видео просмотра
$(document).ready(function(){
    var $imgLinkBtn = $('.section.video .play'),
    	$logo = $('.logo-wrp.main'),
    	$nav = $('#fp-nav'),
    	$lang = $('.lang-wrp'),
    	$menuXS = $('.menu-xs'),
    	$btnClose = $('.btn-main-menu'),
    	$header = $('header'),
    	$iframe = $('#video');

     $imgLinkBtn.on('click', function(){
     	$logo.hide();
     	$nav.hide();
     	$lang.css('opacity', 0);
     	$menuXS.hide();
     	$btnClose.data('video', '1');
     	$header.addClass('opened');
     	var $parent = $(this).hide().prev().hide().parent();
     	$parent.find('iframe').show();
        $parent.find('iframe')[0].src += "&autoplay=1";
    });

    $btnClose.on('click', function(){
    	if (!$(this).data('video')){
    		return;
    	}

     	$btnClose.removeData('video');
     	$header.removeClass('opened');
     	$menuXS.show();
     	$iframe.hide().next().show().next().show();
     	$logo.show();
     	$nav.show();
     	$lang.css('opacity', 1);
    });
});

// создание точек навигации на слайдере 5-ой секции
$(document).ready(function(){
	var $slider = $('.fp-slides');
	if (!$slider.length){
		return;
	}

	var $btnPrev = $('.fp-controlArrow.fp-prev');

	// Создаём контейнер, чтобы уместить в нём стрелки и пагинацию
	$('<div/>',{
		'class' : 'slidesNav-wrp',
		insertAfter : $slider
	});

	// Переносим существующие стрелки в созданный контейнер
	$('.fp-controlArrow').appendTo('.slidesNav-wrp');
	// Создаём контейнер для пагинации
	$('<div/>',{
		'class' : 'fp-slidesNav',
		'id' : 'sliderNav',
		html : $('<ul/>', {}),
		insertAfter : $btnPrev
	});

	// Создаём элементы управления слайдером (точки)
	for (var i=0; i<$slider.find('.slide').length; i++){
		$('<li/>',{
			appendTo : $('#sliderNav ul'),
			html : $('<a/>',{
					html : $('<span/>',{})
				})
		});
	}

	// Назначаем первой точке активный класс
	$('#sliderNav ul li:first-child a').addClass('active');
});